// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-dondeestamos-js": () => import("./../../../src/pages/dondeestamos.js" /* webpackChunkName: "component---src-pages-dondeestamos-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-somosblasfemia-js": () => import("./../../../src/pages/somosblasfemia.js" /* webpackChunkName: "component---src-pages-somosblasfemia-js" */),
  "component---src-pages-vinitos-js": () => import("./../../../src/pages/vinitos.js" /* webpackChunkName: "component---src-pages-vinitos-js" */)
}

